.the-items {
  position: relative;
  width: 55rem;
  min-height: 62.5rem;
  max-width: 100%;
  padding: 5rem 2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade2);
}

.the-items__list {
  width: 80%;
}

.the-items__item {
  width: 100%;
  min-width: 100%;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.2rem;
  font-size: 2.8rem;
  line-height: 4rem;
  letter-spacing: 0.2rem;
  color: var(--accent);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 50%,
    var(--vivid-purple) 50%
  );
  background-size: 200% auto;
  background-position: -0% 0;
  background-repeat: repeat-x;
  background-clip: padding-box;
  cursor: pointer;
  transition: 0.4s ease-in-out, color 0.2s ease-in-out;
}

.the-items__item--highlight {
  background-position: -99.9% 0;
  color: var(--light);
}

.the-items__item span {
  transform: translateX(0);
  transition: transform 0.4s ease-in-out;
}

.the-items__item--highlight span {
  display: block;
  transform: translateX(1rem);
}

@media (max-width: 850px) {
  .the-items {
    width: 100%;
    max-width: 60rem;
    min-height: unset;
    padding: 2rem 0;
    justify-content: flex-start;
  }
  .the-items__item {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
  .the-items__list {
    width: 96%;
  }
}
