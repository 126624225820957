.home {
  width: 100%;
  margin: 0 auto;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.home__value-props {
  width: 90%;
  max-width: 80rem;
  min-width: 90%;
  margin: 0 auto;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
