.the-firm__title {
  color: var(--primary);
  font-family: var(--heading2-font-family);
  font-size: 6rem;
  line-height: 6rem;
  letter-spacing: -0.2rem;
}

.the-firm__title em {
  font-family: var(--heading2-font-family);
}

.the-firm__title--numb {
  color: var(--secondary);
  font-family: var(--heading-thin);
  font-size: 5rem;
  line-height: 3rem;
}

@media (max-width: 850px) {
  .the-firm__title:not(.the-firm__title--numb) {
    margin-bottom: 2rem;
  }

  .the-firm__title {
    font-size: 4.8rem;
    line-height: 4.8rem;
  }

  .the-firm__title--numb {
    font-size: 3.8rem;
    line-height: 2rem;
  }
}
