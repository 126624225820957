.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  flex-basis: 100%;
  max-width: 120rem;
  min-height: 70rem;
  margin: 0 auto;
  padding: 10rem 0;
  /* margin-bottom: 10rem; */
}

@media (max-width: 850px) {
  .hero-section {
    width: 90%;
    max-width: 60rem;
    padding-top: 5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
