.understand-jewelry {
  position: relative;
  width: 90%;
  max-width: 65rem;
  min-height: 25rem;
  margin-bottom: 10rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.understand-jewelry__descriptor {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 40rem;
  /* min-width: 39rem; */
}

.understand-jewelry__small-title {
  display: block;
  text-align: left;
  font-size: 1.8rem;
  margin: 4rem 0 0.5rem;
  color: var(--purple);
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--caption-font-family);
}

.understand-jewelry__title {
  margin-bottom: 1rem;
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: 3rem;
  font-weight: bold;
}

.understand-jewelry__subtitle {
  font-size: 2rem;
  color: var(--secondary);
}

a.understand-jewelry__learn-more {
  margin-top: 1rem;
  font-size: 1.6rem;
  color: var(--link);
}

a.understand-jewelry__learn-more:hover {
  color: var(--link-change);
  transform: translateX(1rem);
}

.understand-jewelry__imagery-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 1.2rem;
}

.understand-jewelry__imagery-wrapper:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: var(--shade2_100);
  border-width: 2rem;
}

.understand-jewelry__imagery {
  position: relative;
  width: 150px;
  height: 150px;
  margin-top: 2rem;
  border-radius: 1.2rem;
  background: var(--shade2_100);
  overflow: hidden;
}

.understand-jewelry__imagery div {
  width: 150px;
  height: 150px;
  border-radius: 1.2rem;
  animation-name: slide-down-vert;
  animation-duration: 12s;
  /* animation-delay: 6s; */
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  background-image: url(./assets/jewelry-vert.png);
  background-size: 150px 350px;
}

@keyframes slide-down-vert {
  0% {
    background-position: 0 -10px;
  }

  5% {
    background-position: 0 -10px;
  }

  35% {
    background-position: 0 -10px;
  }

  40% {
    background-position: 0 -10px;
  }

  68% {
    background-position: 0 165px;
  }

  73% {
    background-position: 0 165px;
  }

  90% {
    background-position: 0 165px;
  }

  100% {
    background-position: 0 -10px;
  }
}

@media (max-width: 750px) {
  .understand-jewelry,
  .understand-jewelry__small-title {
    text-align: center;
  }

  .understand-jewelry__small-title {
    font-size: 1.8rem;
  }

  .understand-jewelry__title {
    font-size: 3rem;
  }

  .understand-jewelry {
    flex-direction: column-reverse;
    justify-content: center;
    margin: 5rem 0 10rem;
  }

  .understand-jewelry__descriptor {
    align-items: center;
  }

  a.understand-jewelry__learn-more {
    padding: 1rem 2rem;
    margin: 2rem auto;
    font-size: 2rem;
    color: var(--link);
    text-align: center;
    border: 1px solid var(--link);
    border-radius: 1.2rem;
  }

  a.understand-jewelry__learn-more:hover {
    background: var(--hover);
    border-color: var(--link-change);
    color: var(--link-change);
    transform: translateY(-1px);
  }

  .understand-jewelry__imagery-wrapper {
    margin-bottom: 2rem;
  }

  .understand-jewelry__imagery-wrapper:after {
    display: none;
  }
}
