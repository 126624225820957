.list-tabs__tab-container {
  position: absolute;
  top: -4.3rem;
  left: 0;
  width: 100%;
  height: 4.3rem;
  min-height: 4.3rem;
  max-height: 4.3rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.list-tabs__tab:not(.list-tabs__tab--active):hover {
  color: unset;
  background: var(--popover--item-bg__hover);
}

.list-tabs__tab {
  position: relative;
  width: 100%;
  padding-left: 1rem;
  box-shadow: none;
  border: 1px solid var(--popover-bg);
  background: var(--faint);
  text-align: left;
  text-transform: capitalize;
  font-size: 1.7rem;
  transition: var(--transition);
  outline: 0;
  cursor: pointer;
}

.list-tabs__tab-label,
.list-tabs__tab {
  height: 3rem;
  line-height: 2.8rem;
  max-width: 8rem;
  padding-right: 1rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  color: var(--secondary);
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.list-tabs__tab:nth-child(2) {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-right: 0;
}

.list-tabs__tab:nth-child(3) {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-left: 0;
}

.list-tabs__tab-container .list-tabs__tab-label {
  width: 18rem;
  max-width: unset;
  padding: 0;
  line-height: 3rem;
  color: var(--purple);
  font-size: 1rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  font-size: 1rem;
  text-align: left;
}

.list-tabs__tab-container .list-tabs__tab--active {
  background: var(--popover--tab-bg__active);
  color: var(--light);
  font-weight: bold;
  cursor: default;
  pointer-events: none;
}

@media (max-width: 850px) {
  .list-tabs__tab-container {
    width: 95%;
    justify-content: flex-end;
    display: none;
  }
}
